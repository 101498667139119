import * as React from "react"
import { useCallback, useState } from "react"
import { type IWorkAssignmentConsultant, WORK_ASSIGNMENT_CONSULTANT_ENDPOINT } from "../../../shared/models/IWorkAssignmentConsultant"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  type SelectChangeEvent,
  Typography
} from "@mui/material"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { type IConsultant } from "../../../shared/models/main/IConsultant"
import { CONNECTION_ERROR, type IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import HelpDocsDrawer from "../../../shared/components/help/HelpDocsDrawer"
import DialogControls from "../../../shared/components/DialogControls"

interface IProps {
  wac?: IWorkAssignmentConsultant
  reviewType?: "Admin" | "Tech" | "Tech 2"
  buttonColor?: "success" | "error" | "info" | "warning"
  onChange: () => void
}

interface IPatch {
  scoring_formatting_quality: number
  scoring_technical_quality: number
  scoring_responsiveness: number
  scoring_admin_review_comment: string
  scoring_tech_review_comment: string
  scoring_tech_2_review_comment: string
}

const repository = new RestRepository<IPatch>(WORK_ASSIGNMENT_CONSULTANT_ENDPOINT)

const scoreOptions = [
    { value: 5, label: "5 - Excellent" },
    { value: 4, label: "4 - Good" },
    { value: 3, label: "3 - Fair" },
    { value: 2, label: "2 - Poor" },
    { value: 1, label: "1 - Very Poor" },
];

/**
 * Edit the work assignment consultant report score.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the component.
 */
const ConsultantScoring: React.FunctionComponent<IProps> = (props: IProps) => {
  const { wac, onChange, reviewType = "Review", buttonColor = "info" } = props

  const consultant = wac?.consultant as IConsultant
  const [scoringFormattingQuality, setScoringFormattingQuality] = useState<number>(0)
  const [scoringTechnicalQuality, setScoringTechnicalQuality] = useState<number>(0)
  const [scoringResponsiveness, setScoringResponsiveness] = useState<number>(0)
  const [adminReviewComment, setAdminReviewComment] = useState<string>("")
  const [techReviewComment, setTechReviewComment] = useState<string>("")
  const [tech2ReviewComment, setTech2ReviewComment] = useState<string>("")
  const [open, setOpen] = useState(false)

  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const handleOpen = useCallback(() => {
    if (wac !== undefined) {
      setScoringFormattingQuality(wac.scoring_formatting_quality)
      setScoringTechnicalQuality(wac.scoring_technical_quality)
      setScoringResponsiveness(wac.scoring_responsiveness)
      setAdminReviewComment(wac.scoring_admin_review_comment)
      setTechReviewComment(wac.scoring_tech_review_comment)
      setTech2ReviewComment(wac.scoring_tech_2_review_comment)
    }
    setOpen(true)
  }, [wac])

  const handleCancel = useCallback(() => {setOpen(false)}, [])

  const handleUpdate = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()
      setSavingError(null)

      try {
        if (wac?.id !== undefined) {
          setSaving(true)
          const patch: IPatch = {
            scoring_formatting_quality: scoringFormattingQuality,
            scoring_technical_quality: scoringTechnicalQuality,
            scoring_responsiveness: scoringResponsiveness,
            scoring_admin_review_comment: adminReviewComment,
            scoring_tech_review_comment: techReviewComment,
            scoring_tech_2_review_comment: tech2ReviewComment
          }
          await repository.patch(patch, wac.id)
          onChange()
          setSaving(false)
          setOpen(false)
        }
      } catch (reason: any) {
        setSaving(false)
        if (reason?.response !== undefined) {
          setSavingError(reason.response as IConnectionError)
        } else {
          setSavingError(CONNECTION_ERROR)
        }
      }
    },
    [wac, scoringFormattingQuality, scoringTechnicalQuality, scoringResponsiveness, adminReviewComment, techReviewComment, tech2ReviewComment]
  )

  const handleScoringFormattingQuality = useCallback((event: SelectChangeEvent<number>) => {
    setScoringFormattingQuality(Number(event.target.value))
  }, [])

  const handleScoringTechnicalQuality = useCallback((event: SelectChangeEvent<number>) => {
    setScoringTechnicalQuality(Number(event.target.value))
  }, [])

  const handleScoringResponsiveness = useCallback((event: SelectChangeEvent<number>) => {
    setScoringResponsiveness(Number(event.target.value))
  }, [])

  const handleAdminReviewComment = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminReviewComment(event.target.value)
  }, [])

    const handleTechReviewComment = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTechReviewComment(event.target.value)
    }, [])

    const handleTech2ReviewComment = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTech2ReviewComment(event.target.value)
    }, [])

  return (
    <>
    {/* Displays button */}
    <Button color={buttonColor} onClick={handleOpen}>{reviewType}</Button>
      {/* Displays dialog box */}
      <Dialog open={open} disableEscapeKeyDown={true} maxWidth="sm" fullWidth>
        <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
              <Grid item>
                  Score <em>{consultant?.name}</em>
              </Grid>
              <Grid item>
                <Typography component="span">
                  Scoring Criteria
                </Typography>
                <HelpDocsDrawer showButton showButtonLabel={false} pageId={559} />
              </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
          <form method="post" onSubmit={handleUpdate}>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems="top">
                {savingError !== null && <Grid item xs={12}>
                <ErrorMessage error={savingError} />
            </Grid>}
                <Grid item xs>
                    <FormControl fullWidth>
                        <InputLabel>Responsiveness</InputLabel>
                        <Select
                            value={scoringResponsiveness}
                            disabled={saving}
                            onChange={handleScoringResponsiveness}
                            label="Responsiveness"
                        >
                            {scoreOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs>
                    <FormControl fullWidth>
                        <InputLabel>Formatting Quality</InputLabel>
                        <Select
                            value={scoringFormattingQuality}
                            disabled={saving}
                            onChange={handleScoringFormattingQuality}
                            label="Formatting Quality"
                        >
                            {scoreOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {(reviewType === "Tech" || reviewType === "Tech 2") && (
                <Grid item xs>
                    <FormControl fullWidth>
                        <InputLabel>Technical Quality</InputLabel>
                        <Select
                            value={scoringTechnicalQuality}
                            disabled={saving}
                            onChange={handleScoringTechnicalQuality}
                            label="Technical Quality"
                        >
                            {scoreOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                )}
                {reviewType === "Admin" && (
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        multiline
                        label="Admin Review Comment"
                        value={adminReviewComment}
                        disabled={saving}
                        onChange={handleAdminReviewComment}
                        />
                    </Grid>
                )}
                {reviewType === "Tech" && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            label="Tech Review Comment"
                            value={techReviewComment}
                            disabled={saving}
                            onChange={handleTechReviewComment}
                        />
                    </Grid>
                )}
                {reviewType === "Tech 2" && (
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        multiline
                        label="Tech 2 Review Comment"
                        value={tech2ReviewComment}
                        disabled={saving}
                        onChange={handleTech2ReviewComment}
                        />
                    </Grid>
                )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleUpdate} onCancel={handleCancel} loading={saving} buttonLabel="Save"/>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConsultantScoring